import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; 
import { useNavigate } from 'react-router-dom';

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

const IzinSayfasi = () => {
    const [personeller, setPersoneller] = useState([]);
    const [izinler, setIzinler] = useState([]);
    const [selectedPersonel, setSelectedPersonel] = useState('');
    const [izinTur, setIzinTur] = useState('Yıllık İzin');
    const [basTar, setBasTar] = useState('');
    const [bitTar, setBitTar] = useState('');
    const [basSaat, setBasSaat] = useState('');
    const [bitSaat, setBitSaat] = useState('');
    const [aciklama, setAciklama] = useState('');
    const role = localStorage.getItem('role');
    const username = localStorage.getItem('username');

    const navigate = useNavigate();


    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    useEffect(() => {
        fetch(`http://localhost:5000/api/personel?role=${role}&username=${username}`)
            .then(response => response.json())
            .then(data => {
                setPersoneller(data);
                if (role !== '1') {
                    setSelectedPersonel(data[0]?.TELNO);
                }
            });

        fetchIzinler();
    }, [role, username]);

    const fetchIzinler = () => {
        fetch('http://localhost:5000/api/izinler?role=' + role + '&username=' + username)
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setIzinler(data);
                } else {
                    console.error('Beklenen dizi formatında değil:', data);
                    setIzinler([]); // Boş bir dizi ile güncelle
                }
            })
            .catch(error => console.error('İzinler alınırken hata oluştu:', error));
    };
    

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            TELNO: selectedPersonel,
            IZINTUR: izinTur,
            BASTAR: basTar,
            BITTAR: bitTar,
            BASSAAT: izinTur === 'Saatlik İzin' ? basSaat : null,
            BITSAAT: izinTur === 'Saatlik İzin' ? bitSaat : null,
            ACIKLAMA: aciklama
        };
        console.log(payload);
        fetch('http://localhost:5000/api/izin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                fetchIzinler(); // İzinler güncellendiğinde tabloyu yenile
            });
    };

    const handleOnayla = (telno) => {
        fetch('http://localhost:5000/api/izin/onayla', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ telno })
        })
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                fetchIzinler(); // İzinler güncellendiğinde tabloyu yenile
            })
            .catch(error => console.error('Fetch error:', error));
    };
    

    const handleIptal = (telno,PK) => {
        console.log("Telno:", telno, "PK:", PK);
        const IPTAL_ACK = prompt('İptal nedenini girin:'); // İptal nedeni için basit bir prompt
        if (IPTAL_ACK) {
            fetch('http://localhost:5000/api/izin/iptal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ telno,PK, IPTAL_ACK })
            })
                .then(response => response.json())
                .then(data => {
                    alert(data.message);
                    fetchIzinler(); // İzinler güncellendiğinde tabloyu yenile
                })
                .catch(error => console.error('Fetch error:', error));
        }
    };

    
    

    return (
        <div className="home-container">
            <Sidebar handleLogout={handleLogout} />
            <div className="form-container">
            {/* İzin Formu */}
            <form onSubmit={handleSubmit}>
                {role === '1' && (
                    <select value={selectedPersonel} onChange={(e) => setSelectedPersonel(e.target.value)}>
                        {personeller.map(personel => (
                            <option key={personel.TELNO} value={personel.TELNO}>{personel.RNAME}</option>
                        ))}
                    </select>
                )}
                <select value={izinTur} onChange={(e) => setIzinTur(e.target.value)}>
                    <option value="Yıllık İzin">Yıllık İzin</option>
                    <option value="Günlük İzin">Günlük İzin</option>
                    <option value="Saatlik İzin">Saatlik İzin</option>
                </select>
                <input type="date" value={basTar} onChange={(e) => setBasTar(e.target.value)} required />
                <input type="date" value={bitTar} onChange={(e) => setBitTar(e.target.value)} required />
                {izinTur === 'Saatlik İzin' && (
                    <>
                        <input type="time" value={basSaat} onChange={(e) => setBasSaat(e.target.value)} required />
                        <input type="time" value={bitSaat} onChange={(e) => setBitSaat(e.target.value)} required />
                    </>
                )}
                <textarea value={aciklama} onChange={(e) => setAciklama(e.target.value)} placeholder="Açıklama" />
                <button type="submit">Kaydet ve Onaya Gönder</button>
            </form>

            {/* İzin Tablosu */}
            <table>
                <thead>
                    <tr>
                        <th>Adı Soyadı</th>
                        <th>İzin Türü</th>
                        <th>Başlangıç Tarihi</th>
                        <th>Bitiş Tarihi</th>
                        <th>Açıklama</th>
                        <th>Durum</th>
                        <th>İptal Nedeni</th>
                        {role === '1' && <th>İşlemler</th>}
                    </tr>
                </thead>
                <tbody>
                    {izinler.map(izin => (
                        <tr key={izin.PK}>
                            <td>{izin.RNAME}</td>
                            <td>{izin.IZINTUR}</td>
                            <td>{formatDate(izin.BASTAR)}</td>
                            <td>{formatDate(izin.BITTAR)}</td>
                            <td>{izin.ACIKLAMA}</td>
                            <td>{izin.ONAY === 0 ? 'Bekliyor' : izin.ONAY === 1 ? 'Onaylandı' : 'İptal Edildi'}</td>
                            <td>{izin.IPTAL_ACK}</td>
                            {role === '1' && (
                                <td>
                                    <button onClick={() => handleOnayla(izin.TELNO)}>Onayla</button>
                                    <button onClick={() => handleIptal(izin.TELNO)}>İptal</button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    );
};

export default IzinSayfasi;
