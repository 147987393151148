import React, { useState, useEffect  } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import loginImage from './LoginImage2.png';
import errorImage from './error.png'; // Hata resmi ekleyin
import Person from './person';
import Sidebar from './Sidebar'; 
import UserForm from './UserForm';
import IzinSayfasi from './IzinSayfasi';
import UserManagement from './UserManagement';

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await fetch('http://localhost:5000/login', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ username, password, company })
          });
          const data = await response.json();
          if (data.success) {
              localStorage.setItem('username', username);
              localStorage.setItem('role', data.role); // Yetki alanını da saklıyoruz
              navigate('/home');
          } else {
              setError('Hatalı kullanıcı adı, şifre veya kurum kısa kodu.');
          }
      } catch (error) {
          console.error('There was an error logging in!', error);
      }
  };

  return (
      <div className="login-container">
          <div className="image-container">
              <img src={loginImage} alt="Login Illustration" style={{ width: '50%', height: '50%' }} />
          </div>
          <div className="form-container">
              <form onSubmit={handleSubmit}>
                  <label>
                      Kullanıcı Adı:
                      <input
                          type="text"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                      />
                  </label>
                  <label>
                      Şifre:
                      <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                      />
                  </label>
                  <label>
                      Kurum Kısa Kodu:
                      <input
                          type="text"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          required
                      />
                  </label>
                  <button type="submit">Giriş Yap</button>
                  {error && <p className="error-message">{error}</p>}
              </form>
          </div>
      </div>
  );
};

const HomePage = () => {
  const [personelData, setPersonelData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Bugünün tarihi varsayılan olarak
  const navigate = useNavigate();

  const handleLogout = () => {
    console.log('Çıkış yapılıyor...'); 
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    navigate('/');
  };


  useEffect(() => {
    const fetchPersonelData = async () => {
      try {
        const response = await fetch(`http://localhost:5000/login/person?date=${selectedDate}`);
        
        if (!response.ok) {
          const errorData = await response.text();
          throw new Error(`Network response was not ok: ${response.status} ${response.statusText} - ${errorData}`);
        }

        const data = await response.json();
        console.log('Fetched Data:', data);
        setPersonelData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPersonelData();
  }, [selectedDate]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="home-container">
      <Sidebar handleLogout={handleLogout} />
      <main>
        <h1>Personel Giriş/Çıkış Bilgileri</h1>
        <label htmlFor="date-picker">Tarih Seç:</label>
        <input
          className='input-date'
          type="date"
          id="date-picker"
          value={selectedDate}
          onChange={handleDateChange}
          max={new Date().toISOString().split('T')[0]} 
        />
    
        <table>
          <thead>
            <tr>
            <th>AD SOYAD</th>
              
              <th>TARİH</th>
              <th>GİRİŞ SAATİ</th>
              <th>ÇIKIŞ SAATİ</th>
              <th>DURUM</th>
              {/* Diğer sütunlar */}
            </tr>
          </thead>
          <tbody>
  {personelData.length > 0 ? (
    personelData.map(personel => {
      // DURUM değerine göre sınıf ve metin belirleme
      const durumClass = personel.DURUM === 2 ? 'exit' : 'entry';
      const durumText = personel.DURUM === 2 ? 'Çıkış Yaptı' : 'Giriş Yaptı';
      
      return (
        <tr key={personel.id}>
          <td>{personel.RNAME}</td>
          
          <td>{formatDate(personel.DATEG)}</td>
          <td>{personel.TIMEG}</td>
          <td>{personel.TIMEC}</td>
          <td className={durumClass}>{durumText}</td>
         
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="5">Bu tarihte kaydınız yoktur.</td>
    </tr>
  )}
</tbody>
        </table>
      </main>
    </div>
  );
};

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <div className="not-found-container">
            <img src={errorImage} alt="Error" className="error-image" />
            <p>Bir hata oluştu. Lütfen tekrar giriş yapınız.</p>
            <button onClick={() => navigate('/')}>Giriş Sayfasına Dön</button>
        </div>
    );
};

const App = () => (
    <Router>
        <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/pjoin" element={<UserForm />} />
            <Route path="/pedit" element={<UserManagement/>} />
            <Route path="/person" element={<Person />} />
            <Route path="/permission" element={<IzinSayfasi />} />
            <Route path="/help" element={<div>İletişim Sayfası</div>} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
    </Router>
);

export default App;
