import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'; 

const UserForm = () => {
  const [realname, setRealname] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('');
  const [company, setCompany] = useState('');
  const [eposta, setEposta] = useState('');
  const [excelFile, setExcelFile] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const navigate = useNavigate();


  const handleLogout = () => {
      localStorage.removeItem('username');
      navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
        realname,
      username,
      password,
      phone,
      company,
      status,
      eposta
    };

    try {
      const response = await fetch('http://localhost:5000/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });

      if (response.ok) {
        alert('Kullanıcı başarıyla eklendi!');
      } else {
        alert('Kullanıcı ekleme başarısız oldu.');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  const handleFileChange = (e) => {
    setExcelFile(e.target.files[0]);
  };


  const handleExcelSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', excelFile);

    try {
      const response = await fetch('http://localhost:5000/api/users/excel', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        alert('Kullanıcılar başarıyla eklendi!');
      } else {
        alert('Excel dosyasından kullanıcı ekleme başarısız oldu.');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  return (
<div className="home-container">
            <Sidebar handleLogout={handleLogout} />
            <main>
      <h1>Kullanıcı Ekle</h1>
      <form onSubmit={handleSubmit}>
      <div>
          <label>Kullanıcı Ad Soyad:</label>
          <input type="text" value={realname} onChange={(e) => setRealname(e.target.value)} required />
        </div>
        <div>
          <label>Kullanıcı ID:</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </div>
        <div>
          <label>Şifre:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        <div>
          <label>Telefon:</label>
          <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </div>
        <div>
          <label>Durum:</label>
          <input type="text" value={status} onChange={(e) => setStatus(e.target.value)} required />
        </div>
        <div>
          <label>Şirket Kısa Kod:</label>
          <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} required />
        </div>
        <div>
          <label>eposta:</label>
          <input type="text" value={eposta} onChange={(e) => setEposta(e.target.value)} required />
        </div>
        <button type="submit">Kaydet</button>
      </form>
      <h2>Excelden Aktar</h2>
      <form onSubmit={handleExcelSubmit}>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} required />
        <button type="submit">Excelden Aktar</button>
      </form>
      </main>
    </div>
  );
};

export default UserForm;
