// Sidebar.js
import React, { useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ handleLogout }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const [rname, setRname] = useState(''); 
    const navigate = useNavigate();
  
    const username = localStorage.getItem('username');
    
    const role = localStorage.getItem('role'); // Kullanıcının yetkisini localStorage'dan alıyoruz
    useEffect(() => {
      // API'den RNAME bilgisini alıyoruz
      fetch(`http://localhost:5000/api/personel/${username}`)
          .then((response) => response.json())
          .then((data) => {
              console.log(data); // Gelen veriyi kontrol etmek için
              setRname(data.RNAME);
          })
          .catch((error) => {
              console.error('RNAME alınırken hata oluştu:', error);
          });
  }, [username]);
  

    return (
      <>
        <header>
          <button className="menu-button" onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? '✖' : '☰'}
          </button>
        </header>
        <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
          <ul>
          <li style={{textAlign:'right',color:'white',fontSize:'15px'}}>Hoş Geldiniz.</li>

            <li style={{textAlign:'right',color:'white',fontSize:'25px'}}>{rname}</li>
            <li><button onClick={() => navigate('/home')}>ANSAYFA</button></li>
            {role === '1' && (
              <li>
                <button onClick={() => setSubmenuOpen(!submenuOpen)}>PERSONEL</button>
                {submenuOpen && (
                  <ul>
                    <li><button onClick={() => navigate('/pjoin')}>PERSONEL KAYIT</button></li>
                    <li><button onClick={() => navigate('/pedit')}>PERSONEL DÜZENLE</button></li>
                    <li><button onClick={() => navigate('/person')}>PERSONEL TAKİP</button></li>
                  </ul>
                )}
              </li>
            )}
            <li><button onClick={() => navigate('/permission')}>İZİN TALEP</button></li>
            <li><button onClick={() => navigate('/help')}>İLETİŞİM</button></li>
            
            <li><button className="menu-button" onClick={handleLogout}>ÇIKIŞ YAP</button></li>
          </ul>
        </div>
        <footer>İDEKOD © Tüm hakları saklıdır</footer>
      </>
    );
  };

export default Sidebar;
