import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import './App.css';

const Personel = () => {
    const [personelList, setPersonelList] = useState([]);
    const [selectedPersonel, setSelectedPersonel] = useState(null);
    const [updatedInfo, setUpdatedInfo] = useState({
        RNAME: '',
        USERN: '',
        PASSWORD: '',
        STATE: '',
        COMPANY: '',
        telno: '',
        eposta: '',
        role: ''
    });
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    useEffect(() => {
        fetch('http://localhost:5000/api/personeledit')
            .then(response => response.json())
            .then(data => setPersonelList(data))
            .catch(error => console.error('Personel bilgileri alınırken hata oluştu:', error));
    }, []);

    const handleSelectChange = (e) => {
        const selected = personelList.find(p => p.PK.toString() === e.target.value);
        setSelectedPersonel(selected);
        if (selected) {
            setUpdatedInfo({
                RNAME: selected.RNAME,
                USERN: selected.USERN,
                PASSWORD: selected.PASSWORD,
                STATE: selected.STATE,
                COMPANY: selected.COMPANY,
                telno: selected.telno,
                eposta: selected.eposta,
                role: selected.role
            });
        }
    };

    const handleUpdate = () => {
        if (selectedPersonel) {
            fetch(`http://localhost:5000/api/personel/${selectedPersonel.telno}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedInfo),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Update Response:', data);
                    // Güncelleme sonrası kullanıcı listesini tekrar yükleyin
                    fetch('http://localhost:5000/api/personeledit')
                        .then(response => response.json())
                        .then(data => setPersonelList(data))
                        .catch(error => console.error('Personel bilgileri alınırken hata oluştu:', error));
                })
                .catch(error => console.error('Personel bilgileri güncellenirken hata oluştu:', error));
        }
    };

    return (
        <div className="home-container">
            <div className="form-container">
                <Sidebar handleLogout={handleLogout} />
                <div className="editcombobox">
                    <select onChange={handleSelectChange} value={selectedPersonel ? selectedPersonel.PK : ""}>
                        <option value="" disabled>Kullanıcı Seçin</option>
                        {personelList.map(personel => (
                            <option key={personel.PK} value={personel.PK.toString()}>
                                {personel.RNAME}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedPersonel && (
                    <div className="editcombobox">
                        <h2>Kullanıcı Bilgileri</h2>
                        <label>
                            Adı:
                            <input
                                type="text"
                                value={updatedInfo.RNAME}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, RNAME: e.target.value })}
                            />
                        </label>
                        <label>
                            Kullanıcı Adı:
                            <input
                                type="text"
                                value={updatedInfo.USERN}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, USERN: e.target.value })}
                            />
                        </label>
                        <label>
                            Şifre:
                            <input
                                type="password"
                                value={updatedInfo.PASSWORD}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, PASSWORD: e.target.value })}
                            />
                        </label>
                        <label>
                            Durum:
                            <input
                                type="text"
                                value={updatedInfo.STATE}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, STATE: e.target.value })}
                            />
                        </label>
                        <label>
                            Şirket:
                            <input
                                type="text"
                                value={updatedInfo.COMPANY}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, COMPANY: e.target.value })}
                            />
                        </label>
                        <label>
                            Telefon:
                            <input
                                type="text"
                                value={updatedInfo.telno}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, telno: e.target.value })}
                            />
                        </label>
                        <label>
                            E-posta:
                            <input
                                type="email"
                                value={updatedInfo.eposta}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, eposta: e.target.value })}
                            />
                        </label>
                        <label>
                            Rol:
                            <input
                                type="text"
                                value={updatedInfo.role}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, role: e.target.value })}
                            />
                        </label>
                        <button onClick={handleUpdate}>Güncelle</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Personel;
