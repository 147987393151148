import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; 
import { useNavigate } from 'react-router-dom';

function UserList() {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('aktif');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const role = localStorage.getItem('role');
  const username = localStorage.getItem('username');

  
  const navigate = useNavigate();


  const handleLogout = () => {
      localStorage.removeItem('username');
      navigate('/');
  };

  // Sayfa açıldığında aktif kullanıcıları getir
  useEffect(() => {
    fetchUsers('aktif');
  }, []);

  // API'den kullanıcıları çek
  const fetchUsers = (userType) => {
    let apiUrl = '';
    if (userType === 'aktif') apiUrl = 'http://localhost:5000/aktif';
    else if (userType === 'bekleyen') apiUrl = 'http://localhost:5000/bekleyen';
    else if (userType === 'pasif') apiUrl = 'http://localhost:5000/pasif';

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Eğer dönen veri bir array değilse boş bir array olarak set et
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          setUsers([]);
        }
        setSelectedUsers([]);
        setAllSelected(false);
      })
      .catch((error) => console.error('Error:', error));
  };

  // Combobox'ta seçim yapıldığında filtreyi değiştir ve kullanıcıları tekrar getir
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);
    fetchUsers(selectedFilter);
  };

  // Checkbox ile kullanıcıları seç
  const handleUserSelect = (telno) => {
    if (selectedUsers.includes(telno)) {
      setSelectedUsers(selectedUsers.filter((userTelno) => userTelno !== telno));
    } else {
      setSelectedUsers([...selectedUsers, telno]);
    }
  };

  // Tüm kullanıcıları seç
  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedUsers([]);
    } else {
      const allUserTelnos = users.map((user) => user.TELNO);
      setSelectedUsers(allUserTelnos);
    }
    setAllSelected(!allSelected);
  };

  // API'ye aktif et veya iptal et isteği gönder
  const handleStatusChange = (newState) => {
    fetch('http://localhost:5000/update-users', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ telnos: selectedUsers, state: newState }),
    })
      .then((response) => response.json())
      .then((data) => {
        // İşlem başarılı olursa kullanıcıları tekrar getir
        fetchUsers(filter);
      })
      .catch((error) => console.error('Error:', error));
  };

  return (
    <div className='home'>
   <Sidebar handleLogout={handleLogout} />
    
      <h1>Kullanıcı Listesi</h1>
      <select onChange={handleFilterChange} value={filter}>
        <option value="aktif">Aktif Kullanıcılar</option>
        <option value="bekleyen">Bekleyen Kullanıcılar</option>
        <option value="pasif">Pasif Kullanıcılar</option>
      </select>
    
      {filter === 'bekleyen' && (
        <div>
          <button onClick={() => handleStatusChange(1)}>Seçilenleri Aktif Et</button>
          <button onClick={() => handleStatusChange(3)}>Seçilenleri İptal Et</button>
          <button onClick={handleSelectAll}>{allSelected ? 'Tümünü Seçme' : 'Tümünü Seç'}</button>
        </div>
      )}

      <table>
        <thead>
          <tr>
            <th>PK</th>
            <th>Adı</th>
            <th>Telefon</th>
            <th>Durum</th>
            {filter === 'bekleyen' && <th>Seç</th>}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.TELNO}>
              <td>{user.PK}</td>
              <td>{user.RNAME}</td>
              <td>{user.TELNO}</td>
              <td>{user.STATE=== 0 ? 'Onay Bekliyor' : user.STATE == 1 ? 'Aktif Kullanıcı' : user.STATE == 2 ? 'Pasif Kullanıcı':'test'}</td>
              {filter === 'bekleyen' && (
                <td>
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.TELNO)}
                    onChange={() => handleUserSelect(user.TELNO)}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
     
    </div>
  );
}

export default UserList;
